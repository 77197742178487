.badge-wrapper {
  position: relative;
  display: inline-block;
  margin: 0 15px;
}

.badge {
  position: absolute;
  left: 100%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  width: auto;
  height: auto;
  padding: 0 6px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background-color: red;
  border-radius: 50%;
  border: solid 2px #ffff
}

.badge-small {
  min-width: 16px;
  height: 16px;
  font-size: 8px;
}

.badge-large {
  min-width: 20px;
  height: 20px;
  font-size: 12px;
}