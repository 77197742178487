.rc-tree .rc-tree-treenode {
    display: flex;
}

.rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
    height: fit-content;
    width: 100%;
}

.rc-tree-title {
    display: flex;
}

.profil-tree-node-container {
    width: 100%;
}

.profil-tree-node {
    width: 100%;
    color: var(--primary-color);
}

.rc-tree-node-selected {
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
}

.node-switcher-icon {
    cursor: pointer;
}