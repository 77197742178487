.siteUpdateInfos{
    padding:20px  40px 20px 40px;
    .input {
        .input-wrapper {
            border: none !important;
            background-color: unset !important;
        }
    }
}
.button{
    display: block;
    background-color: #1B8ADD ;
    color:white;
    width:100%;
    border-radius: 10px;
    height: 40px;
}