@import "shared/styles/styleGlobal.scss";

.select-label {
  text-align: left;
  font-weight: $select-label-font-weight;
  font-size: $select-label-font-size;
  color: $select-color;
  height: $select-label-font-size + 10px;
  // margin: $select-label-margin;
}

.select-to-search-input {
  padding: 0 10px;

  input[type='text'] {
    all: unset;
    overflow: hidden;
    margin-right: 10px;
    color:$select-color;
  }
}

.select-to-search-selected {
  color: #303030;
  font-size: 11px;
  text-align: left;
  font-weight: 500;
  white-space: nowrap;
  /* Empêche le texte de se retourner */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Affiche "..." lorsque le texte dépasse */
  padding-right: 5px;
  /* Espace à droite pour le bouton de suppression */
}

.select-to-search-remove {
  padding: 0 5px;
  cursor: pointer;
  color: #303030;
  font-size: 11px;
  text-align: left;
  font-weight: 600;
}

.select-to-search-remove:hover {
  font-size: 12px;
}

.select {
  border: $select-border;
  border-radius: $select-border-radius;
  background-color: $select-background-color;
  position: relative;
  cursor: pointer;
  // margin: $select-margin;
  height: $select-height;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

input[type="checkbox"]+span {
  padding-left: 12px;
}

.select.disabled {
  background-color: $select-disabled-bg-color;
  cursor: not-allowed;
}

.select-input {
  padding: 10px 13px;
  overflow: hidden;
}

.selected-value,
.select-placeholder,
.select-option-label {
  font-size: 13px;
  color: $select-color;
  text-align: left;
  font-weight: 500;
}

.select-option-group-label {
  font-size: 11px;
  color: #174A84;
  font-weight: 600;
  margin: 5px 10px 0 10px;
}

.selected-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-options {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #338EF5;
  border-top: none;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  padding-bottom: 10px;
  margin-top: -1px;
  height: fit-content;
}

.select-option-element.selected {
  background-color: #ECF6FC;

  span {
    font-weight: bold;
  }
}

.select-options.top {
  bottom: 100%;
}

.select-options.top-left {
  right: 0;
  bottom: 100%;
}

.select-options.top-right {
  left: 0;
  bottom: 100%;
}

.select-options.bottom {
  top: 100%;
  max-height: 290px;
  overflow-y: scroll;

}

.select-options.bottom-left {
  right: 0;
  top: 100%;
}

.select-options.bottom-right {
  left: 0;
  top: 100%;
}

.select-options.dropdownMatchSelectWidth,
.select.search-to-select .select-options.dropdownMatchSelectWidth {
  border-top: 1px solid #338EF5;
  border-radius: 3px;
}


.select.search-to-select .select-options.bottom.dropdownMatchSelectWidth,
.select.search-to-select .select-options.bottom-left.dropdownMatchSelectWidth,
.select.search-to-select .select-options.bottom-right.dropdownMatchSelectWidth {
  margin-top: 2px;
}

.select-options.top.dropdownMatchSelectWidth,
.select-options.top-left.dropdownMatchSelectWidth,
.select-options.top-right.dropdownMatchSelectWidth,
.select.search-to-select .select-options.top.dropdownMatchSelectWidth,
.select.search-to-select .select-options.top-left.dropdownMatchSelectWidth,
.select.search-to-select .select-options.top-right.dropdownMatchSelectWidth {
  margin-bottom: 2px;
}

.select-option-element {
  padding: 0 14px 2px 14px;
  margin: 3px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-option-element:hover {
  background-color: #ECF6FC;
}

.select-arrow {
  padding-right: 13px;
}

.select-arrow img {
  width: 12px;
  height: 12px;
}

.select-arrow.open img {
  transform: rotate(-90deg);
}

.select-arrow:not(.open) img {
  transform: rotate(90deg);
}

.select-search-input input {
  height: 40px;
  background-color: #F7F9FC;
  border: 1px solid #A8C4E5;
  border-radius: 5px;
  padding: 0 10px;
}

.select-search-input,
.select-to-search-input input::-webkit-input-placeholder {
  /* Edge */
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  color: #C2C2C2;
  font-weight: 500;
}

.select-search-input,
.select-to-search-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  color: #C2C2C2;
  font-weight: 500;
}

.select-search-input,
.select-to-search-input input::placeholder {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  color: #C2C2C2;
  font-weight: 500;
}

.select-container {
  position: relative
}

.select-to-search-list {

  overflow: hidden;
  max-width: 100%;
  position: absolute;
  top: 100%;
  left: 0
}

.select.search-to-select {
  border: solid 1px#A8C4E5;
  border-radius: $select-border-radius;
  background-color: #F7F9FC;
  position: relative;
  cursor: pointer;
  // margin: $select-margin;
  height: $select-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
}