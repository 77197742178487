@import 'shared/styles/colors.scss';
.widget {
    width: 300px;
    min-width: 250px;
    height: 200px;
    box-shadow: 0px 17px 30px #0000001A;
    border: 1px solid #BDDFFE;
    border-radius: 6px;
    // margin: 20px;

    &-header {
        border-bottom: 1px solid #BDDFFE;
        padding: 5px 10px;
    }

    &-title {
        color: $colorSecondary_6;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
    }

    &-content {
        padding: 10px;
    }
}