.custom-node {
    position: relative;
    padding-left: 10px;
}

.custom-node::after {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 10px;
    height: 2px;
    background-color: var(--primary-color);
}

.custom-node::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    background-color: var(--primary-color);
}

.custom-node-default::before {
    top: 0;
    bottom: 0;
}

.last-last-child::before {
    top: -10px;
    bottom: -10px;
}

.solo-child::before {
    top: -10px;
    height: 20px;
}

.first-child::before {
    top: -10px;
    bottom: 0;
}