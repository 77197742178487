.cnxIcon {
    max-width: "10px";
    max-height: "10px";
}

.site-panel-header {
    background-color: #1b8add;
    color: #fff;
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid #fff;
}

.search-site {
    border: solid 1px#A8C4E5;
    border-radius: 5px;
    background-color: #F7F9FC;
    position: relative;
    cursor: pointer;
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-site input {
    all: unset;
    width: 170px
}

.filters > * {
    margin-right: 0 !important;
}