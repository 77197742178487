@import "shared/styles/styleGlobal.scss";
.c-card {
  box-shadow: $card-box-shadow;
  border: $card-border;
  border-radius: $card-border-radius;
  text-align: $card-text-align;
  background-color: $card-bg-color;
};

.c-card-header {
  height: 33px;
  background-color: $card-header-bg-color;
  border-radius: $card-header-border-radius; //top-right-borderRadius-cardHeader et top-left-borderRadius-cardHeader doivent être égaux aux top-right-borderRadius-card et top-left-borderRadius-card; bottom-right-borderRadius-cardHeader et bottom-left-borderRadius-cardHeader sont égaux à 0
  padding: $card-header-padding;
};

.c-card-title {
  color: $card-title-color;
  line-height: $card-title-line-height;
  font-size: $card-title-font-size;
};

.c-card-title span{
  margin-left:10px
};

.c-card-body {
  padding: $card-body-padding;
  box-sizing: content-box;
};

// .APP_cardSeparator>hr {
//     border: 0.5px solid #a5c9ff;
// }

.cn_app_cardSeparator>hr {
  border: 0.5px solid #A5C9FF;
}

.cn_app_cardDate {
  color: #00235D;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}

.cn_app_cardAmount {
  color: #000000;
  font-family: Poppins-Bold;
  text-align: center;
}

.cn_app_cardAmountLg {
  font-size: 32px;
  line-height: 43px;
}

.cn_app_cardAmountSm {
  font-size: 16px;
  line-height: 24px;
}

