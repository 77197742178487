.rc-tree .rc-tree-treenode, .rc-tree-title {
    display: flex;
}

.rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
    height: fit-content;
    width: 100%;
}

.rc-tree-node-selected {
    background-color: transparent;
    box-shadow: none;
    opacity: 1;

    .entity-tree-node {
        box-shadow: 0px 0px 10px #00000029;
        border-color: var(--error-color);
    }
}

.rc-tree-treenode {
    .node-switcher-icon {
        transform: rotate(270deg);
    }
}

.rc-tree-treenode.rc-tree-treenode-switcher-open {
    .node-switcher-icon {
        transform: rotate(0deg);
        position: relative;
        top: 5px;
        left: -5px;
    }
}

.entity-tree-node .entity-profil {
    border: solid 1px var(--primary-color);
    width: 100%;
    background-color: #ffff;
    color: var(--primary-color);
}

.rc-tree-treenode {
    border: none !important;// cache la bordur rouge de gauche 
}

.rc-tree-node-content-wrapper > div:nth-child(2) {
    display: none !important; // Cache la div rouge du drag/drop
}

.drop-target.drag-over-customer {
    // background-color: var(--error-color) !important; // rouge quand on drag/drop sur une customer
    background-color: #F4ABAA !important;
}
.drop-target.drag-over-group, .drop-target.drag-over-company {
    // background-color: var(--primary-color) !important; // vert quand on drag/drop sur une group
    background-color: #A7CEFB !important;
}
